<template>
  <div class="group">
    <div class="title">
      {{ title }}
    </div>
    <div class="value">
      {{ value }}
    </div>
  </div>
</template>
<script>

export default {
  name: 'TitleValue',
  props: {
    title: {
      type: String,
      require: true
    },
    value: {
      type: String,
      require: true
    }
  }
}
</script>
<style lang="less" scoped>
  @import '../../style/colors';
  .group {
    font-size: 0.75rem;
    padding-top: 2rem;
    margin-bottom: 0;
    .value {
      color: @color_radiant_pink;
      font-weight: bold;
    }
  }
</style>
